<template>
  <v-container fluid >
    <div style="height:50px">
    </div>
    <v-layout wrap row>
      <v-flex xs12 sm12 md12 class="pb-3">
        <v-card flat
            class="pa-2 vCard"
        >
          <v-row no-gutters>
            <v-col cols="1">
            </v-col>

            <v-col cols="3" style="padding-top: 22px;vertical-align: middle">
              <span style="font-family: Roboto,sans-serif;font-size: 1rem;
    font-weight: 350;">Total:{{totalCnt}}

                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  color="green"
                                  :disabled="isDisableDownloadExcel"
                                  @click="downloadExcel"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>mdi-microsoft-excel</v-icon>
                              </v-btn>
                      </template>
                      <span>검색 결과 전체 액셀 다운로드</span>
                    </v-tooltip>


              </span>
            </v-col>
            <v-col cols="2">
              <v-select hide-details
                        :items="searchType"
                        v-model="selectedTypeValue"
                        label="검색 조건"
                        style="width: 135px"
              ></v-select>
            </v-col>
            <v-col style="vertical-align: bottom" >
              <v-text-field v-model="searchText" hide-details>>
                <template v-slot:label>
                  <v-icon style="vertical-align: middle">
                    mdi-file-find
                  </v-icon>
                  검색어를 입력해주세요
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="1">
              <span style="cursor: pointer">
              <v-btn
                  depressed
                  color="blue-grey"
                  class="ma-2 white--text"
                  height="42px"
                  :loading="loading"
                  :disabled="loading"
                  @click="run"
              >
                검색
              </v-btn>
              </span>


            </v-col>
            <v-col cols="1" style="padding-top: 12px">
              <v-tooltip right style="cursor: pointer">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="reloadInit"
                      v-bind="attrs"
                      v-on="on"
                      style="cursor: pointer"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <span>초기화</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="1">
            </v-col>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="tagDataList"
                  :options.sync="options"
                  :server-items-length="100"
                  :loading="loading"
                  :hide-default-footer="true"
                  :disable-pagination="true"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      size="22"
                      class="mr-2"
                      @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  데이터 없습니다.
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="1">

            </v-col>

          </v-row>
          <v-row>
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-pagination
                  v-model="options.page"
                  :length="pageCnt"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
              ></v-pagination>
            </v-col>

            <v-col cols="3"></v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card >
        <v-card-title style="background-color: orange;font-weight: bolder;color: #ffffff">
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.code"
                    label="Sample ID"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
                <v-text-field
                    v-model="editedItem.name"
                    label="name"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="4"
              >
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="editedItem.tag"
                    label="Tag"
                    hint="테그 입력시 단어의 끝에 ','(쉼표)가 입력 되어야 합니다. ex) 당뇨,비만,과체중,"
                    placeholder="ex) 당뇨,비만,과체중,"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
import XLSX from 'xlsx'

export default {
  data: () => ({
    isDisableDownloadExcel: true,
    pageCnt : 0,
    totalCnt : 0, // 현재 조건에 해당 하는 데이터 수
    searchType : [
      {
        text : "Tag",
        value : "tag",
      },
      {
        text : "Name",
        value : "name",
      },
      {
        text : "Sample ID",
        value : "sample",
      }
    ],
    selectedTypeValue : '',
    searchText : '',
    dialog: false,
    dialogDelete: false,
    loading: true,
    headers: [
      {
        text: 'Sample ID',
        align: 'start',
        sortable: false,
        value: 'code',
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Tags', value: 'tag', sortable: false },
      { text: '수정', value: 'actions',  align: 'center', sortable: false },
    ],
    tagDataList: [],
    options: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      code: '',
      tag: ''
    },
    defaultItem: {
      name: '',
      code: '',
      tag: ''
    },
    isPageEventBlock : false
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : '태그 수정'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      handler () { // TODO 이 부분에서 . 옵션 접근이 가능함 하기 API를 통해서 데이터 표현하고 페이지처리는 그냥 될 것으로 예상 됨, 검색 조건 만 만들면 될 같음.
        if(this.isPageEventBlock === false) {
          this.callApi(false);
        }
      },
      deep: true,
    },
  },
  methods: {
    reloadInit () {
      this.selectedTypeValue = '';
      this.searchText = '';
      this.options.page = 1;
      this.init();
      this.callApi(false);
    },
    downloadExcel () {
      if (this.totalCnt < 1) {
        alert("다운로드 데이터가 없습니다.")
        return;
      }
      let filename = `tags.xlsx`;
      //this.selectedTypeValue = 'excel';
      this.callApi(true, function (data) {
        excelExport(filename, data.tagList)
        .then( () => {
          alert("다운로드가 완료 되었습니다.");
        } )
        .catch( (e)=> {
          console.log(e);
          alert("엑셀 다운로드 실패 :" + e.message);
        });

        async function excelExport (fileName, resultList = []) {
          try {
            if (resultList.length < 1) {
              throw new Error("다운로드 데이터 없음");
            }

            let excelDataList = [];
            resultList.reduce( (acc, obj) => {
              let excelObj = {
                code : obj.code,
                name : obj.name,
                tag  : obj.tag
              }
              excelDataList.push(excelObj);
            })


            let ws = XLSX.utils.json_to_sheet(excelDataList);
            let wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws, 'tags');
            XLSX.writeFile(wb, fileName);

          } catch (e){
            throw new Error(e);
          }

          return true;
        }
      })
    },
    run () {
      if ( !this.selectedTypeValue|| this.selectedTypeValue.length === 0 ) {
        alert("검색 조건을 선택해 주세요.");
        return;
      }
      if ( !this.searchText || this.searchText.length === 0 ) {
        alert("검색어를 입력 해 주세요.");
        return;
      }
      this.options.page = 1; // 검색 시 초기화
      this.callApi(false);
    },
    callApi (isExcelDownload = false, cb) {
      this.loading = true;
      this.isPageEventBlock = true;
      this.sampleHashTagApi(isExcelDownload).then( (data)=> {
        if (typeof(cb) === 'function') {
          cb(data) ;
        } else {
          this.respDataMapping(data);
        }
        this.loading = false;
      }).catch((e) => {
        console.log(e);
        this.init();
      }).finally(() => {
        this.isPageEventBlock = false;
      })
    },
    async sampleHashTagApi(isExcelDownload=false) {
      let requestConfig = {
        params : {
          searchType: this.selectedTypeValue || 'none',
          searchText : this.searchText || '',
          limit : 10,
          page : this.options.page - 1,
          isExcelDownload : isExcelDownload
        }
      }
      if(isNaN(requestConfig.params.page)) requestConfig.params.page = 0;
      let resp = await axios.get(`/v1/sample/hashtag`, requestConfig);
      //console.log("[hashSearch] %s", JSON.stringify(resp));
      if (resp.data.data) {
        return resp.data.data;
      } else {
        alert("검색 조건에 문제가 있습니다. 다시 한번 확인해 주세요.");
        this.init();
        return {};
      }
    },
    init () {
      this.isDisableDownloadExcel = true;
      this.pageCnt = 0;
      this.totalCnt = 0;
    },
    editItem (item) {
      this.editedIndex = this.tagDataList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (this.editedItem.tag.length > 0) this.editedItem.tag += ",";
      this.dialog = true
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save () {
      if(!this.editedItem || this.editedItem.code === '' ) {
        alert("수정 필수 데이터가 없습니다.");
      }
      try {
        let isValidTag = this.checkTag(this.editedItem.tag);
        if(!isValidTag) {
          alert("테그 입력시 단어의 끝에 ','(쉼표)가 입력 되어야 합니다.")
          return false;
        }
        console.dir(this.editedItem)
        await axios.put(`/v1/bio/tag/${this.editedItem.code}`, {tag : this.editedItem.tag} );

        alert("수정 성공");
        this.close();

      } catch (e) {
        console.log(e);
        alert("수정 실패" + e);
        return;
      }

      this.loading = true;
      this.sampleHashTagApi(false).then( (data)=> {
        this.respDataMapping(data);
        this.loading = false;
      })

    },
    respDataMapping(data) {
      this.tagDataList = data.tagList;
      this.pageCnt = data.pageCnt;
      this.totalCnt = data.totalCnt;
      this.isDisableDownloadExcel = (this.totalCnt < 1);
    },
    //Tag 에 마지막 데이터는 꼭 ,(쉼표)로 끝나야 함.
    checkTag(str){
      if(str && str.length > 0){
        let strArr = str.split("");
        return strArr[str.length-1] === ",";
      }
      return true;
    }

  },
}
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-text-field >>> label {
  font-size: 13px;
}
.table { margin-bottom: 0px }
</style>